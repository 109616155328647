<template lang="pug">
.cards(v-if="expense")
  AppTitle(:label="`${expense.category_id[1]} - ${expense.name}`")
  .cards__header
    AppCard
      AppStatusTitle(
      	:type="expense.status_invoice_expense" 
      	:label="expense.status_invoice_expense"
      ) 
      .expense
        .expense__title {{expense.nombre_emisor}}
        .expense__date {{dateFormatter(expense.fecha)}} 
        .expense__total {{numberFormatter(expense.total)}}
        AppRow.my-4
          AppDescription(label="REGIMEN" :description="expense.regimen_fiscal_emisor")
          AppDescription(label="RFC EMISOR" :description="expense.rfc_emisor")
        AppRow.my-4
          AppDescription(label="FECHA TIMBRADO" :description="expense.fecha_timbrado")
          AppDescription(label="CERTIFICADO SAT" :description="expense.no_certificado_sat")
        AppRow.my-4
          AppDescription(label="PROVEEDOR DEL CERTIFICARO" :description="expense.rfc_prov_certificado")
          AppDescription(label="UUID" :description="expense.uuid")
        AppRow.my-4
          AppDescription(label="FORMA DE PAGO" :description="expense.forma_pago")
          AppDescription(label="METODO DE PAGO" :description="expense.metodo_pago")
  .cards__body(v-for="invoice in expense.lines_invoices")
    AppCard(v-for="product in invoice")
      .product__title {{product.descripcion}}
      AppRow
        AppDescription(label="UNIDADES" :description="product.cantidad")
        AppDescription(label="CLAVE" :description="product.clave_producto_servicio")
        AppDescription(label="C/U" :description="product.valor_unitario")
        AppDescription(label="TOTAL" :description="numberFormatter(product.valor_unitario*product.cantidad)")
</template>

<script>
import AppStatusTitle from "@/components/AppStatusTitle";
import AppTitle from "@/components/AppTitle";
import { dateFormatter, numberFormatter } from "@/utils/formatters";
import { ref } from "@vue/reactivity";
import { onMounted } from "@vue/runtime-core";
import { axios } from "@/utils/axios";
import { useRoute } from "vue-router";
import { store } from "@/store";
import { onError } from "@/utils/toastify";
import { AppCard, AppRow, AppDescription, AppRibbon } from "gignet-components";
export default {
  components: {
    AppCard,
    AppRow,
    AppDescription,
    AppTitle,
    AppRibbon,
    AppStatusTitle,
  },
  setup() {
    const expense = ref(null);
    const route = useRoute();

    onMounted(async () => {
      try {
        store.loading = true;
        const { id } = route.params;
        const { data } = await axios.get(`expenses/invoices/${id}`);
        expense.value = data.payload[0];
      } catch (error) {
        onError("Intente mas tarde.");
      } finally {
        store.loading = false;
      }
    });

    return { expense, dateFormatter, numberFormatter };
  },
};
</script>

<style lang="scss" scoped>
.expense {
  @apply text-center;
  &__date {
    @apply text-bluegray-500;
    @apply text-sm;
    @apply mb-1;
    @apply mt-3;
  }
  &__total {
    @apply text-bluegray-500;
    @apply text-2xl;
  }
  &__title {
    @apply text-bluegray-500;
    @apply text-base;
    @apply font-medium;
    @apply mt-1;
  }
}
.cards {
  @apply pb-5;
  @screen md {
    @apply mx-5;
  }
  &__header {
    @apply mb-4;
  }
  &__body {
    @apply my-3;
    .product__title {
      @apply text-xs;
      @apply text-bluegray-400;
      @apply mb-2;
      @apply font-medium;
    }
  }
}
</style>
